e4.news = {
    init: function () {
        var jNewsPaging = jQuery( '.js-e-news-pagination' );
        if ( jNewsPaging.length === 1 ) {
            jNewsPaging.find( 'a' ).not( '.js-e-news-pagination-item' ).addClass( 'page-link' );
            var jNewsPagingList = jQuery( '.js-e-news-pagination-list-dw' ).find( 'a' ),
                jNewsPagingItemTemplate = jQuery( '.js-e-news-pagination-item' ),
                jNewsPagingListNew = [];
            jNewsPagingList.each( function () {
                var jThis = jQuery( this ),
                    jNewsPagingItemNew = jNewsPagingItemTemplate.clone();
                jNewsPagingItemNew.find( 'a' ).attr( 'href', jThis.attr( 'href' ) ).text( jThis.text() );
                if ( jThis.find( 'b' ).length === 1 ) {
                    jNewsPagingItemNew.addClass( 'active' );
                }
                jNewsPagingListNew.push( jNewsPagingItemNew );
            } );
            jNewsPagingItemTemplate.replaceWith( jNewsPagingListNew );
        }
    }
};

