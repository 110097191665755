var e4 = e4 || {};
( function () {
    'use strict';
    var jHTML = jQuery( 'html' );
    e4.settings = {
        lang: jHTML.attr( 'lang' ),
        intergerSeparator: jHTML.data( 'integer-separator' ),
        decimalSeparator: jHTML.data( 'decimal-separator' ),
        classes: {
            resetTransform: 'reset-transform'
        },
        navigation: {
            autoHideTolerance: {
                up: 25,
                down: 'auto'
            },
            revealActive: {
                delay: 400,
                speed: 0.75,
                easing: 'swing'
            }
        },
        parallax: {
            speed: -0.35
        },
        scrollReveal: {
            classAnimateInOnLoad: 'animate-in-on-load',
            classAnimateInOnScroll: 'animate-in-on-scroll'
        },
        scrollToAnchor: {
            speed: 0.5,
            nudge: 2,
            easing: 'swing',
            stickyElements: '.js-e-header',
            backToTop: {
                revealOffset: 1.5
            }
        },
        forms: {
            validate: {
                messages: {
                    da: {
                        required: 'Dette felt er påkrævet.',
                        maxlength: jQuery.validator.format( 'Indtast højst {0} tegn.' ),
                        minlength: jQuery.validator.format( 'Indtast mindst {0} tegn.' ),
                        rangelength: jQuery.validator.format( 'Indtast mindst {0} og højst {1} tegn.' ),
                        email: 'Indtast en gyldig email-adresse.',
                        url: 'Indtast en gyldig URL.',
                        date: 'Indtast en gyldig dato.',
                        number: 'Indtast et tal.',
                        digits: 'Indtast kun hele tal.',
                        equalTo: 'Indtast den samme værdi igen.',
                        range: jQuery.validator.format( 'Angiv en værdi mellem {0} og {1}.' ),
                        max: jQuery.validator.format( 'Angiv en værdi der højst er {0}.' ),
                        min: jQuery.validator.format( 'Angiv en værdi der mindst er {0}.' ),
                        creditcard: 'Indtast et gyldigt kreditkortnummer.'
                    }
                }
            }
        }
    };
}() );

