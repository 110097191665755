var romgummi = romgummi || {};
( function () {
    'use strict';
    jQuery( function () {
        romgummi.init();
    } );
    romgummi.init = function () {
        romgummi.cookie.init();
    };
    romgummi.cookie = {};
    romgummi.cookie.init = function () {
        var jCookieMessage = jQuery( '.e-cookie-message' );
        if ( jCookieMessage.length === 1 ) {
            jCookieMessage.find( '.CookieOptInLevelConfig' ).on( 'click', function ( event ) {
                event.preventDefault();
                jQuery.get( this.href );
                jCookieMessage.addClass( "accepted" );
            } );
        }
    };
}() );

