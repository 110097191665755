e4.scrollReveal = {
    requestingAnimation: false,
    init: function () {
        jQuery( '.js-e-scroll-reveal .e-section' ).each( function () {
            var jThis = jQuery( this );
            if ( e4.util.isInViewport( jThis ) === true ) {
                jThis.addClass( e4.settings.scrollReveal.classAnimateInOnLoad );
            }
        } );
        e4.scrollReveal.bindEvents();
    },
    bindEvents: function () {
        jQuery( window ).on( 'scroll resize', function () {
            if ( e4.scrollReveal.requestingAnimation === false ) {
                window.requestAnimationFrame( function () {
                    e4.scrollReveal.animate();
                    e4.scrollReveal.requestingAnimation = false;
                } );
            }
            e4.scrollReveal.requestingAnimation = true;
        } );
    },
    animate: function () {
        jQuery( '.js-e-scroll-reveal .e-section' ).each( function () {
            var jThis = jQuery( this );
            if ( e4.util.isInViewport( jThis ) === true ) {
                jThis.addClass( e4.settings.scrollReveal.classAnimateInOnScroll );
            }
        } );
    }
};

